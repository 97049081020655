<template>
  <v-card
    flat
    tile
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    v-touch="{
      left: () => onSwipe(),
      right: () => onSwipe(),
    }"
  >
    
    <div class="v-responsive v-image">
      <a 
        v-bind:href="item.video.url"
        class="thumb" 
        target="_blank"
      >
        <img class="thumb" v-bind:src="image"/>
      </a>

      <v-row class="overlay" align="center" justify="center">
        <v-col cols="12">
          <div class="text-truncate">
            <DetailsDialog v-bind:item="item"></DetailsDialog>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import DetailsDialog from './DetailsDialog'

export default {
  props: ['item'],
  computed: {
    image: function() {
      if (this.item.index == null) {
        return this.item.video.default_thumb
      } else {
        return this.item.video.thumbs[this.item.index].src
      }
    },
  },
  methods: {
    onMouseEnter: function () {
      this.startCyclingVideo(this.item.key)
    },
    onMouseLeave: function () {
      this.stopCyclingVideo(this.item.key)
    },
    onSwipe: function () {
      this.toggleCyclingVideo(this.item.key)
    },
    ...mapActions(['startCyclingVideo', 'stopCyclingVideo', 'toggleCyclingVideo'])
  },
  components: { 
    DetailsDialog
  },
  interval: null,
}
</script>

<style lang="scss">
.thumb {
    display: flex;
    width: 100%;
    min-height: 48px;
}

div.overlay {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 48px;
  background: rgba(0, 0, 0, 0.4);
  padding-left: 10px;
  padding-right: 10px;
}
</style>