<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="title"
        primary-title
      >
        Add new item
      </v-card-title>

      <v-card-text>
        <v-form @submit="add">
          <v-text-field
            v-model="id"
            :rules="idRules"
            :error-messages="errorMsg"
            label="Item id or URL"
            type="text"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <div class="flex-grow-1"></div>

        <v-btn
          color="primary"
          text
          @click="cancel"
        >
          Cancel
        </v-btn>

        <v-btn
          color="primary"
          text
          @click="add"
        >
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({
    dialog: false,
    notFound: false,
    id: '',
    idRules: [
      v => !!v || 'Id is required',
    ],
  }),
  computed: {
    errorMsg() {
      return this.notFound ? ['Not found'] : []
    },
  },
  methods: {
    cancel: function() {
      this.dialog = false
      this.notFound = false
      this.id = ''
    },
    add: function() {
      if (this.id == '') {
        return
      }

      this.addItem({
        password: this.$route.params.password,
        id: this.id,
      })
      .then(() => this.cancel())
      .catch(error => this.notFound = true)
    },
    ...mapActions(['addItem']),
  },
}
</script>

<style>

</style>