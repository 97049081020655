<template>
  <span 
    class="video-title" 
    @click="dialog=true"
  >
    {{ details.title }}
  <span>

  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title
        class="title"
        primary-title
      >
        {{ details.title }}
      </v-card-title>

      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr>
              <td>Id</td>
              <td>
                <a 
                  v-bind:href="details.url"
                  target="_blank"
                >
                  {{ item.id }}
                </a>
              </td>
            </tr>
            <tr>
              <td>Duration</td>
              <td>{{ details.duration || '-' }}</td>
            </tr>
            <tr>
              <td>Rating</td>
              <td>{{ details.rating || '-' }}</td>
            </tr>
            <tr>
              <td>Views</td>
              <td>{{ details.views || '-' }}</td>
            </tr>
            <tr>
              <td>Added</td>
              <td>{{ item.date }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <div class="flex-grow-1"></div>

        <v-btn
          color="primary"
          text
          @click="cancel"
        >
          Cancel
        </v-btn>

        <v-btn
          color="primary"
          text
          @click="remove"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({
    dialog: false,
  }),
  props: ['item'],
  computed: {
    details: function() {
      if ('video' in this.item) return this.item.video
      if ('anime' in this.item) return this.item.anime
      if ('manga' in this.item) return this.item.manga
      return null
    },
  },
  methods: {
    cancel: function() {
      this.dialog = false
    },
    remove: function() {
      this.deleteItem({
        password: this.$route.params.password,
        key: this.item.key,
      })
      this.dialog = false
    },
    ...mapActions(['deleteItem']),
  },
}
</script>

<style lang="scss">
span.video-title {
  cursor: pointer;
}
</style>