<template>
  <v-card flat tile>
    
    <div class="v-responsive v-image">
      <a 
        v-bind:href="item.manga.url"
        class="thumb" 
        target="_blank"
      >
        <img class="thumb" referrerpolicy="no-referrer" v-bind:src="item.manga.image"/>
      </a>

      <v-row class="overlay" align="center" justify="center">
        <v-col cols="12">
          <div class="text-truncate">
            <DetailsDialog v-bind:item="item"></DetailsDialog>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import DetailsDialog from './DetailsDialog'

export default {
  props: ['item'],
  components: { 
    DetailsDialog
  },
  interval: null,
}
</script>

<style lang="scss">
.thumb {
    display: flex;
    width: 100%;
}

div.overlay {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 48px;
  background: rgba(0, 0, 0, 0.4);
  padding-left: 10px;
  padding-right: 10px;
}
</style>