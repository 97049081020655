import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from './Login'
import Home from './Home'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Login },
  { path: '/:password', component: Home },
]

export default new VueRouter({
  routes
})
