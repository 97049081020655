import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VIDEOS = 'videos'
const ANIME = 'anime'
const MANGA = 'manga'

function getToday() {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()
  return day + '/' + month + '/' + year
}

function stripId(urlOrId) {
  urlOrId = urlOrId.replace(/ /g, '');
  if (!urlOrId.startsWith('http')) {
    return urlOrId;
  }
  const url = new URL(urlOrId);
  for (const [_, value] of url.searchParams) {
    return value;
  }
  const pathArray = url.pathname.split('/');
  if (pathArray[pathArray.length - 1] == '') {
    return pathArray[pathArray.length - 2];
  } else {
    return pathArray[pathArray.length - 1];
  }
}

let imageInterval = null;

export default new Vuex.Store({
  state: {
    itemsType: VIDEOS,
    videos: null,
    anime: null,
    manga: null,
    cyclingVideoKey: null,
  },
  mutations: {
    setVideosType(state) {
      state.itemsType = VIDEOS
    },
    setAnimeType(state) {
      state.itemsType = ANIME
    },
    setMangaType(state) {
      state.itemsType = MANGA
    },
    setItems(state, items) {
      if (items != null) {
        state.videos = items.videos
        state.anime = items.anime
        state.manga = items.manga
      } else {
        state.videos = null
        state.anime = null
        state.manga = null
      }
    },
    removeVideo(state, key) {
      Vue.delete(state.videos, key)
    },
    removeAnime(state, key) {
      Vue.delete(state.anime, key)
    },
    removeManga(state, key) {
      Vue.delete(state.manga, key)
    },
    addVideo(state, { key, item }) {
      Vue.set(state.videos, key, {
        key: key,
        index: null,
        ...item
      })
    },
    addAnime(state, { key, item }) {
      Vue.set(state.anime, key, {
        key: key,
        ...item
      })
    },
    addManga(state, { key, item }) {
      Vue.set(state.manga, key, {
        key: key,
        ...item
      })
    },
    cycleVideo(state, key) {
      const item = state.videos[key]
      if (item.index == null || item.index + 1 >= item.video.thumbs.length) {
        item.index = 0
      } else {
        item.index++
      }
    },
    resetVideoCycles(state) {
      for (let key in state.videos) {
        state.videos[key].index = null
      }
    },
    setCyclingVideoKey(state, key) {
      state.cyclingVideoKey = key;
    }
  },
  actions: {
    loadItems({ commit }, password) {
      commit('setItems', null)
      fetch('https://react-ec53b.firebaseio.com/' + password + '.json')
        .then(response => response.json())
        .then(data => {
          if (data == null) {
            commit('setItems', {})
          } else {
            var result = {
              [VIDEOS]: {},
              [ANIME]: {},
              [MANGA]: {},
            }
            if (VIDEOS in data) {
              const videos = data[VIDEOS]
              for (var key in videos) {
                result[VIDEOS][key] = {
                  key: key,
                  index: null,
                  ...videos[key]
                }
              }
            }
            if (ANIME in data) {
              const anime = data[ANIME]
              for (var key in anime) {
                result[ANIME][key] = {
                  key: key,
                  ...anime[key]
                }
              }
            }
            if (MANGA in data) {
              const manga = data[MANGA]
              for (var key in manga) {
                result[MANGA][key] = {
                  key: key,
                  ...manga[key]
                }
              }
            }
            commit('setItems', result)
          }
        })
    },
    deleteItem({ state, dispatch }, info) {
      switch (state.itemsType) {
        case VIDEOS:
          dispatch('deleteVideo', info)
          break
        case ANIME:
          dispatch('deleteAnime', info)
          break
        case MANGA:
          dispatch('deleteManga', info)
          break
      }
    },
    deleteVideo({ commit }, { password, key }) {
      fetch('https://react-ec53b.firebaseio.com/' + password + '/' + VIDEOS + '/' + key + '.json', {
        method: 'DELETE',
      })
        .then(response => commit('removeVideo', key))
    },
    deleteAnime({ commit }, { password, key }) {
      fetch('https://react-ec53b.firebaseio.com/' + password + '/' + ANIME + '/' + key + '.json', {
        method: 'DELETE',
      })
        .then(response => commit('removeAnime', key))
    },
    deleteManga({ commit }, { password, key }) {
      fetch('https://react-ec53b.firebaseio.com/' + password + '/' + MANGA + '/' + key + '.json', {
        method: 'DELETE',
      })
        .then(response => commit('removeManga', key))
    },
    addItem({ state, dispatch }, info) {
      switch (state.itemsType) {
        case VIDEOS:
          dispatch('addVideo', info)
          break
        case ANIME:
          dispatch('addAnime', info)
          break
        case MANGA:
          dispatch('addManga', info)
          break
      }
    },
    addVideo({ commit }, { password, id }) {
      id = stripId(id);
      return fetch('https://script.google.com/macros/s/AKfycbw_-73dnJrTgZIksEkPjftFZ6-3ar_JAvUXtI4_pfxMowHE6gY06r8kFF455MLP5csGPQ/exec?id=' + id)
        .then(response => response.json())
        .then(info => {
          if (info.video) {
            return {
              id: id,
              date: getToday(),
              video: info.video
            };
          } else {
            throw { message: 'Not found' }
          }
        })
        .then(info => {
          return fetch('https://react-ec53b.firebaseio.com/' + password + '/' + VIDEOS + '.json', {
            method: 'POST',
            body: JSON.stringify(info),
          })
            .then(response => response.json())
            .then(name => commit('addVideo', { key: name.name, item: info }))
        })
    },
    addAnime({ commit }, { password, id }) {
      id = stripId(id);
      return fetch('https://script.google.com/macros/s/AKfycbzrV2PuQC2QeTAIYzmiqLT1wgrhFIYoBQd2BAvB_BmeEy_XfcT1/exec?id=' + id)
        .then(response => response.json())
        .then(info => {
          if (info) {
            return {
              id: id,
              date: getToday(),
              anime: info
            };
          } else {
            throw { message: 'Not found' }
          }
        })
        .then(info => {
          return fetch('https://react-ec53b.firebaseio.com/' + password + '/' + ANIME + '.json', {
            method: 'POST',
            body: JSON.stringify(info),
          })
            .then(response => response.json())
            .then(name => commit('addAnime', { key: name.name, item: info }))
        })
    },
    addManga({ commit }, { password, id }) {
      id = stripId(id);
      return fetch('https://script.google.com/macros/s/AKfycbxcrZ8Jbu1617R91FMsekQCJocI-SZZS6P7EJqYVVaI5HYtvRqG/exec?id=' + id)
        .then(response => response.json())
        .then(info => {
          if (info) {
            return {
              id: id,
              date: getToday(),
              manga: info
            };
          } else {
            throw { message: 'Not found' }
          }
        })
        .then(info => {
          return fetch('https://react-ec53b.firebaseio.com/' + password + '/' + MANGA + '.json', {
            method: 'POST',
            body: JSON.stringify(info),
          })
            .then(response => response.json())
            .then(name => commit('addManga', { key: name.name, item: info }))
        })
    },
    startCyclingVideo({ state, commit, dispatch }, key) {
      if (imageInterval != null) {
        dispatch('stopCyclingVideo')
      }
      state.cyclingVideoKey = key;
      commit('cycleVideo', key);
      imageInterval = setInterval(() => commit('cycleVideo', key), 500);
    },
    stopCyclingVideo({ commit }) {
      if (imageInterval != null) {
        clearInterval(imageInterval);
        imageInterval = null;
      }
      commit('resetVideoCycles');
      commit('setCyclingVideoKey', null);
    },
    toggleCyclingVideo({ state, dispatch }, key) {
      const cyclingVideoKey = state.cyclingVideoKey;
      if (cyclingVideoKey != null) {
        dispatch('stopCyclingVideo');
        if (cyclingVideoKey != key) {
          dispatch('startCyclingVideo', key);
        }
      } else {
        dispatch('startCyclingVideo', key);
      }
    },
  },
})
