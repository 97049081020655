<template>
  <v-app id="react-lista">
    <v-app-bar app color="orange" dark>
      <v-toolbar-title color="white">React lista</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items>
        <v-btn
          text
          :class="{ 'v-btn--active': itemsType == 'videos' }"
          @click="setVideosType"
        >
          Videos
        </v-btn>
        <v-btn
          text
          :class="{ 'v-btn--active': itemsType == 'anime' }"
          @click="setAnimeType"
        >
          Anime
        </v-btn>
        <v-btn
          text
          :class="{ 'v-btn--active': itemsType == 'manga' }"
          @click="setMangaType"
        >
          Manga
        </v-btn>
      </v-toolbar-items>

      <AddDialog></AddDialog>
    </v-app-bar>

    <v-content>
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import AddDialog from "./AddDialog";

export default {
  name: "app",
  components: {
    AddDialog,
  },
  computed: {
    ...mapState(["itemsType"]),
  },
  methods: {
    ...mapMutations(["setVideosType", "setAnimeType", "setMangaType"]),
  },
};
</script>

<style lang='scss' scoped>
</style>