<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar dark flat>
            <v-toolbar-title>Decode list</v-toolbar-title>
            <div class="flex-grow-1"></div>
          </v-toolbar>

          <v-card-text>
            <v-form @submit="validate">
              <v-text-field
                v-model="password"
                :rules="passwordRules"
                label="Password"
                type="password"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          
          <v-card-actions>
            <v-btn color="primary" @click="validate">Open</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    password: '',
    passwordRules: [
      v => !!v || 'Password is required',
    ],
  }),
  methods:{
    validate: function (e) {
      if (this.password) {
        this.$router.push(this.password)
      }
      e.preventDefault()
    }
  },
}
</script>

<style lang="scss" scoped>

</style>