<template>
  <VideoList v-if="itemsType == 'videos'"></VideoList>
  <AnimeList v-else-if="itemsType == 'anime'"></AnimeList>
  <MangaList v-else-if="itemsType == 'manga'"></MangaList>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from './Loading'
import VideoList from './VideoList'
import AnimeList from './AnimeList'
import MangaList from './MangaList'

export default {
  computed: {
    ...mapState(['itemsType']),
  },
  methods: {
    ...mapActions(['loadItems']),
  },
  created: function () {
    this.loadItems(this.$route.params.password)
  },
  watch: {
    '$route' (to, from) {
      this.loadItems(this.$route.params.password)
    }
  },
  components: {
    Loading, VideoList, AnimeList, MangaList
  }
}
</script>

<style lang="scss">
</style>