<template>
  <v-container 
    v-if="videos == null"
    class="fill-height"
    fluid
  >
    <Loading></Loading>
  </v-container>
  
  <v-container 
    v-else
    fluid
  >
    <v-row>
      <v-col
        v-for="item in orderedVideos"
        :key="item.key"
        cols="12"
        lg="custom-5"
        class="pa-1"
        pa
      >
        <Video v-bind:item="item"></Video>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from './Loading'
import Video from './Video'

export default {
  computed: {
    orderedVideos: function() {
      const videos = Object.values(this.videos)
      videos.reverse()
      return videos
    },
    ...mapState(['videos']),
  },
  components: {
    Loading, Video
  }
}
</script>

<style lang="scss">
@media (min-width: 1264px) {
  .col-lg-custom-5 {
      flex: 0 0 20%;
      max-width: 20%;
  }
}

@media (min-width: 1264px) {
  .col-lg-custom-8 {
      flex: 0 0 12.5%;
      max-width: 12.5%;
  }
}
</style>