<template>
  <v-container 
    v-if="anime == null"
    class="fill-height"
    fluid
  >
    <Loading></Loading>
  </v-container>
  
  <v-container 
    v-else
    fluid
  >
    <v-row>
      <v-col
        v-for="item in orderedAnime"
        :key="item.key"
        cols="6"
        lg="custom-8"
        class="pa-1"
      >
        <Anime v-bind:item="item"></Anime>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from './Loading'
import Anime from './Anime'

export default {
  computed: {
    orderedAnime: function() {
      const anime = Object.values(this.anime)
      anime.reverse()
      return anime
    },
    ...mapState(['anime']),
  },
  components: {
    Loading, Anime
  }
}
</script>

<style lang="scss">
</style>
