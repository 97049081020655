import Vue from 'vue'
import Vuetify from "vuetify"
import colors from 'vuetify/lib/util/colors'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: colors.orange.base,
        secondary: colors.orange.base,
        accent: colors.orange.base,
      },
    }
  },
})
