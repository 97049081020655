<template>
  <v-container 
    v-if="manga == null"
    class="fill-height"
    fluid
  >
    <Loading></Loading>
  </v-container>
  
  <v-container 
    v-else
    fluid
  >
    <v-row>
      <v-col
        v-for="item in orderedManga"
        :key="item.key"
        cols="6"
        lg="custom-8"
        class="pa-1"
      >
        <Manga v-bind:item="item"></Manga>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from './Loading'
import Manga from './Manga'

export default {
  computed: {
    orderedManga: function() {
      const manga = Object.values(this.manga)
      manga.reverse()
      return manga
    },
    ...mapState(['manga']),
  },
  components: {
    Loading, Manga
  }
}
</script>

<style lang="scss">
</style>
