import Vue from 'vue'
import vuetify from './src/plugins/vuetify'
import store from './src/store.js'
import router from './src/router.js'
import App from './src/App.vue'

new Vue({
  vuetify,
  store,
  router,
  render: createElement => createElement(App)
}).$mount('#app')
